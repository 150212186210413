<template>
	<div class="full-width grey1-bg p-t p-b flex-center-content">
		<div
			id="assets-group-summary-loader"
			class="full-height flex-center-content"
			v-if="loading"
		>
			<Loader />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else id="assets-group-summary" class="flex-center-content">
			<div class="full-height full-width" style="width: 250px">
				<div class="full-height grid-with-same-rows-height gap-s">
					<AssetsGroupSummaryCol1Element
						icon="plug-cable"
						icon_color="var(--blue1)"
						:value="
							assets_group_summary.energy_systems_summary.total_peak_power
						"
						unit="kW"
						:label="
							$t(
								'entities.assets_group_summary.energy_systems_summary.total_peak_power'
							)
						"
					/>
					<AssetsGroupSummaryCol1Element
						v-if="!assets_group_summary.has_assets_with_multiple_systems"
						icon="lightning"
						icon_color="#FCB12A"
						:value="
							assets_group_summary.energy_systems_summary
								.today_energy_production
						"
						unit="kWh"
						:label="
							$t(
								'entities.assets_group_summary.energy_systems_summary.today_energy_production'
							)
						"
					/>
					<AssetsGroupSummaryCol1Element
						v-if="!assets_group_summary.has_assets_with_multiple_systems"
						symbol="%"
						icon_color="#FFFFFF"
						:value="assets_group_summary.energy_systems_summary.pr_average"
						unit="%"
						:label="
							$t(
								'entities.assets_group_summary.energy_systems_summary.pr_average'
							)
						"
					/>

					<AssetsGroupSummaryCol1Element
						v-if="assets_group_summary.has_assets_with_multiple_systems"
						icon="lightning"
						icon_color="#FCB12A"
						:value="
							assets_group_summary.energy_systems_summary
								.current_month_energy_self_consumption
						"
						unit="kWh"
						:label="
							$t(
								'entities.assets_group_summary.energy_systems_summary.current_month_savings'
							)
						"
					/>

					<AssetsGroupSummaryCol1Element
						symbol="€"
						icon_color="var(--green1)"
						:value="
							assets_group_summary.energy_systems_summary.current_month_savings
						"
						unit="€"
						:label="
							$t(
								'entities.assets_group_summary.energy_systems_summary.current_month_savings'
							)
						"
					/>
				</div>
			</div>

			<div class="chart-container grey4-bg rounded-s box-shadow1">
				<div class="flex-auto-full-height-content">
					<div class="header p-l p-t p-r">
						<span class="text-uppercase font-700 font-m">
							{{ $t("dashboard.summary.status") }}
						</span>
					</div>
					<div class="content">
						<div class="grid-cols">
							<div class="full-height flex-center-content">
								<PieChart
									:data="systems_status_data"
									:hide_legend="true"
									:hide_tooltips="true"
									:hide_datalabels="true"
									:height_in_px="220"
									:is_donut="true"
									:donut_middle_label="$t('dashboard.summary.systems')"
									donut_middle_text_color="white"
								/>
							</div>
							<div class="flex-vertical-center" style="height: 180px">
								<AssetsGroupSummaryDonutChartLabels
									:data="systems_status_data"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="chart-container grey4-bg rounded-s box-shadow1">
				<div class="flex-auto-full-height-content">
					<div class="header p-l p-t p-r">
						<span class="text-uppercase font-700 font-m">
							{{ $t("dashboard.summary.active_alerts") }}
						</span>
					</div>
					<div class="content">
						<div class="grid-cols">
							<div class="full-height flex-center-content">
								<PieChart
									:height_in_px="220"
									:data="alerts_data"
									:hide_legend="true"
									:hide_tooltips="true"
									:hide_datalabels="true"
									:is_donut="true"
									:donut_middle_label="$t('dashboard.summary.alerts')"
									donut_middle_text_color="white"
								/>
							</div>
							<div class="flex-vertical-center" style="height: 180px">
								<AssetsGroupSummaryDonutChartLabels :data="alerts_data" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import AssetsGroupSummaryCol1Element from "./assets-group-summary-shared/AssetsGroupSummaryCol1Element.vue";
import PieChart from "@sub_components/charts/PieChart.vue";
import AssetsGroupSummaryDonutChartLabels from "./assets-group-summary-shared/AssetsGroupSummaryDonutChartLabels.vue";

import AssetsGroupSummaryFinderClass from "@src/assets-group/application/assets-group-summary-finder.js";
const AssetsGroupSummaryFinder = new AssetsGroupSummaryFinderClass();

export default {
	name: "AssetsGroupSummary",
	components: {
		Loader,
		Alert,
		AssetsGroupSummaryCol1Element,
		PieChart,
		AssetsGroupSummaryDonutChartLabels
	},
	props: {
		assets_group_id: String
	},
	data() {
		return {
			loading: true,
			error: null,
			assets_group_summary: null
		};
	},
	mounted() {
		this.load_assets_group_data();
	},
	methods: {
		async load_assets_group_data() {
			this.loading = true;

			try {
				this.assets_group_summary =
					this.assets_group_id == "default"
						? await AssetsGroupSummaryFinder.get_default_assets_group_summary()
						: await AssetsGroupSummaryFinder.get_assets_group_summary(
								this.assets_group_id
						  );
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		systems_status_data() {
			let data = [
				{
					label: this.$t("entities.assets_group_summary.systems_status.ok"),
					color: "var(--green1)",
					value: null
				},
				{
					label: this.$t("entities.assets_group_summary.systems_status.faulty"),
					color: "var(--warning-color)",
					value: null
				},
				{
					label: this.$t(
						"entities.assets_group_summary.systems_status.offline"
					),
					color: "var(--danger-color)",
					value: null
				},
				{
					label: this.$t(
						"entities.assets_group_summary.systems_status.unavailable"
					),
					color: "var(--grey2)",
					value: null
				}
			];

			if (
				!this.assets_group_summary ||
				!this.assets_group_summary.systems_status
			)
				return data;

			const systems_status = this.assets_group_summary.systems_status;
			data[0].value = systems_status.ok;
			data[1].value = systems_status.faulty;
			data[2].value = systems_status.offline;
			data[3].value = systems_status.unavailable + systems_status.unsupported;

			return data;
		},
		alerts_data() {
			let data = [
				{
					label: this.$t("entities.alert.alert_type_options.provider_alert"),
					color: "var(--blue1)",
					value: null
				},
				{
					label: this.$t("entities.alert.alert_type_options.pr_alert"),
					color: "var(--warning-color)",
					value: null
				},
				{
					label: this.$t(
						"entities.alert.alert_type_options.suspicious_data_alert"
					),
					color: "var(--grey2)",
					value: null
				}
			];

			if (!this.assets_group_summary || !this.assets_group_summary.alerts)
				return data;

			const alerts = this.assets_group_summary.alerts;
			data[0].value = alerts.provider_alert;
			data[1].value = alerts.pr_alert;
			data[2].value = alerts.suspicious_data_alert;

			return data;
		},
		assets_types_data() {
			const assets_types =
				this.assets_group_summary && this.assets_group_summary.assets_types
					? this.assets_group_summary.assets_types
					: null;
			return [
				{
					label: this.$t(
						"entities.energy_system.system_type_options.only_generation"
					),
					color: "#FCB12A",
					value: assets_types ? assets_types.generation : null
				},
				{
					label: this.$t(
						"entities.energy_system.system_type_options.only_consumption"
					),
					color: "var(--green1)",
					value: assets_types ? assets_types.consumption : null
				},
				{
					label: this.$t(
						"entities.energy_system.system_type_options.generation_and_consumption"
					),
					color: "#ABB1B7",
					value: assets_types ? assets_types.generation_and_consumption : null
				},
				{
					label: this.$t(
						"entities.energy_system.system_type_options.only_grid_interaction"
					),
					color: "#FFFFFF",
					value: assets_types ? assets_types.only_grid_interaction : null
				}
			];
		}
	},
	watch: {
		assets_group_id(new_value) {
			this.load_assets_group_data();
		}
	}
};
</script>

<style>
:root {
	--assets-group-summary-height: 270px;
}
</style>
<style scoped>
#assets-group-summary-loader {
	height: var(--assets-group-summary-height);
}
#assets-group-summary {
	height: var(--assets-group-summary-height);
	gap: 15px;
}

#assets-group-summary > div {
	height: var(--assets-group-summary-height);
}

.chart-container {
	width: 470px;
}

.grid-cols {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 190px;
	justify-content: center;
	align-content: center;
}
</style>
