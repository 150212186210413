<template>
	<div>
		<Loader v-if="loading" size="20" />
		<div v-else>
			<Multiselect
				v-model="selected_assets_group_id"
				:options="multiselect_options"
				track_by="assets_group_id"
				:multiple="false"
			/>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "./Multiselect.vue";

import AssetsGroupsFinderClass from "@src/assets-group/application/assets-groups-finder.js";
const AssetsGroupsFinder = new AssetsGroupsFinderClass();

export default {
	name: "AssetsGroupMultiselect",
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: true,
			multiselect_options: null,
			selected_assets_group_id: "default"
		};
	},
	async mounted() {
		try {
			this.loading = true;
			this.multiselect_options = [
				{
					assets_group_id: "default",
					label: "Default"
				}
			];

			const assets_groups = await AssetsGroupsFinder.get_assets_groups();

			if (assets_groups) {
				const multiselect_values = assets_groups.map((item) => {
					return {
						assets_group_id: item.id,
						label: item.name
					};
				});

				this.multiselect_options =
					this.multiselect_options.concat(multiselect_values);
			}
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	watch: {
		selected_assets_group_id(new_value) {
			this.$emit("input", new_value);
		}
	}
};
</script>
