<template>
	<div class="full-width full-height white-bg rounded-s box-shadow p-sm">
		<div v-if="loading" class="flex-center-content full-height">
			<Loader />
		</div>
		<div v-else>
			<div class="display-flex">
				<router-link
					:to="{
						name: 'assets-group-config'
					}"
				>
					<button
						class="full-rounded white-bg box-shadow flex-center-content grey1-color p-xxs"
						style="width: 25px; height: 25px"
					>
						<Icon icon="chevron-left" size="16" />
					</button>
				</router-link>
			</div>

			<div v-if="error" class="m-t">
				<Alert :message="error" />
			</div>

			<div class="m-t" id="add-new-assets-group-form">
				<div class="font-bold">
					{{ $t("entities.assets_group.name") }}
				</div>
				<div></div>
				<div>
					<TextInput v-model="new_assets_group.name" />
				</div>
				<div>
					<button class="btn btn-primary" v-on:click="create_assets_group">
						{{ $t("general.add") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../sub_components/Loader.vue";
import Alert from "../../../sub_components/Alert.vue";
import TextInput from "../../../sub_components/form_components/TextInput.vue";

import AssetsGroupCreatorClass from "../../../../../../src/assets-group/application/assets-group-creator.js";
const AssetsGroupCreator = new AssetsGroupCreatorClass();

export default {
	name: "NewAssetsGroup",
	components: {
		Loader,
		Alert,
		TextInput
	},
	data() {
		return {
			loading: false,
			error: null,
			new_assets_group: {
				name: null
			}
		};
	},
	methods: {
		async create_assets_group() {
			this.loading = true;
			this.error = null;

			try {
				const new_assets_group = await AssetsGroupCreator.create_assets_group(
					this.new_assets_group
				);
				this.$router.push({
					name: "edit-assets-group",
					params: {
						assets_group_id: new_assets_group.id
					}
				});
			} catch (error) {
				this.error = error;
				setTimeout(() => {
					this.error = null;
				}, 10000);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style scoped>
#add-new-assets-group-form {
	display: grid;
	grid-template-columns: 300px auto;
	row-gap: 10px;
	column-gap: 15px;
}
</style>
