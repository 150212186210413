<template>
	<div class="absolute full-width full-height white-bg rounded-s box-shadow">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm">
			<div class="header p-b-l">
				<div class="display-flex">
					<router-link
						:to="{
							name: 'assets-group-config'
						}"
					>
						<button
							class="full-rounded white-bg box-shadow flex-center-content grey1-color p-xxs"
							style="width: 25px; height: 25px"
						>
							<Icon icon="chevron-left" size="16" />
						</button>
					</router-link>
				</div>

				<div v-if="error" class="m-t m-b">
					<Alert :message="error" />
				</div>

				<div class="m-t">
					<div class="edit-assets-group-form">
						<div class="font-bold">
							{{ $t("entities.assets_group.name") }}
						</div>
						<div></div>
						<div>
							<TextInput v-model="assets_group.name" />
						</div>
						<div>
							<button class="btn btn-primary" v-on:click="update_assets_group">
								{{ $t("general.save") }}
							</button>
						</div>
					</div>
				</div>

				<div class="display-flex-space-between m-t">
					<div v-if="loading_add_assets_to_assets_group">
						<Loader size="25" />
					</div>

					<div v-else class="display-flex gap-m">
						<div style="width: 350px">
							<LocationSelect
								v-model="assets_to_add"
								:multiple="true"
								:allow_select_all="false"
								:assets_filter="is_asset_allowed_to_be_added_on_group"
							/>
						</div>
						<div>
							<button
								class="btn btn-primary"
								v-on:click="add_assets_to_assets_group"
							>
								{{ $t("general.add") }}
							</button>
						</div>
						<div v-if="add_assets_to_assets_group_error">
							<Alert :message="add_assets_to_assets_group_error" />
						</div>
					</div>
					<TextInput
						style="width: 250px"
						v-model="assets_search_text"
						:placeholder="$t('general.search')"
					/>
				</div>
			</div>
			<div class="content">
				<DataTable
					:items="assets_group_assets"
					:items_per_page="50"
					:fields="data_table_fields"
					:filter="assets_search_text"
					:filter_fields="['name', 'custom_id']"
				>
					<template #cell(options)="data">
						<div v-if="loading_remove_asset_from_assets_group == data.item.id">
							<Loader size="24" />
						</div>
						<div
							v-else-if="!loading_remove_asset_from_assets_group"
							class="cursor-pointer danger-color"
							v-on:click="remove_asset_from_assets_group(data.item.id)"
						>
							<Icon icon="trash" size="20" />
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../sub_components/Loader.vue";
import Alert from "../../../sub_components/Alert.vue";
import TextInput from "../../../sub_components/form_components/TextInput.vue";
import LocationSelect from "../../../sub_components/form_components/LocationSelect2.vue";
import DataTable from "../../../sub_components/DataTable.vue";

import AssetsGroupsFinderClass from "../../../../../../src/assets-group/application/assets-groups-finder";
import AssetsGroupAssetsFinderClass from "../../../../../../src/assets-group/application/assets-group-assets-finder";
import AssetsGroupUpdaterClass from "../../../../../../src/assets-group/application/assets-group-updater";
import AssetsGroupAssetsUpdaterClass from "../../../../../../src/assets-group/application/assets-group-assets-updater";

const AssetsGroupsFinder = new AssetsGroupsFinderClass();
const AssetsGroupAssetsFinder = new AssetsGroupAssetsFinderClass();
const AssetsGroupUpdater = new AssetsGroupUpdaterClass();
const AssetsGroupAssetsUpdater = new AssetsGroupAssetsUpdaterClass();

export default {
	name: "NewAssetsGroup",
	components: {
		Loader,
		Alert,
		TextInput,
		LocationSelect,
		DataTable
	},
	data() {
		return {
			loading: true,
			error: null,
			assets_group: null,
			assets_group_assets: null,
			assets_search_text: null,
			assets_to_add: null,
			loading_add_assets_to_assets_group: false,
			add_assets_to_assets_group_error: null,
			loading_remove_asset_from_assets_group: false
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const assets_group_id = this.$route.params.assets_group_id;
			const assets_group = await AssetsGroupsFinder.get_assets_group(
				assets_group_id
			);
			this.assets_group = Object.assign({}, assets_group);

			this.assets_group_assets =
				await AssetsGroupAssetsFinder.get_assets_group_assets(assets_group_id);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async update_assets_group() {
			this.error = null;
			this.loading = true;

			try {
				const assets_group_to_update = Object.assign({}, this.assets_group);
				await AssetsGroupUpdater.update_assets_group(
					this.assets_group.id,
					assets_group_to_update
				);
			} catch (error) {
				this.error = error;
				setTimeout(() => {
					this.error = null;
				}, 10000);
			} finally {
				this.loading = false;
			}
		},
		is_asset_allowed_to_be_added_on_group(asset) {
			return !this.assets_group_assets.find((item) => item.id == asset.id);
		},
		async add_assets_to_assets_group() {
			this.loading_add_assets_to_assets_group = true;
			this.add_assets_to_assets_group_error = null;

			try {
				await AssetsGroupAssetsUpdater.add_assets_to_assets_group(
					this.assets_group.id,
					this.assets_to_add
				);

				this.assets_to_add = null;
			} catch (error) {
				this.add_assets_to_assets_group_error = error;
				setTimeout(() => {
					this.add_assets_to_assets_group_error = null;
				}, 8000);
			} finally {
				this.loading_add_assets_to_assets_group = false;
			}
		},
		async remove_asset_from_assets_group(asset_id) {
			try {
				this.loading_remove_asset_from_assets_group = asset_id;

				await AssetsGroupAssetsUpdater.remove_assets_to_assets_group(
					this.assets_group.id,
					[asset_id]
				);

				this.loading_add_assets_to_assets_group = true;
				setTimeout(() => {
					this.loading_add_assets_to_assets_group = false;
				}, 5);
			} catch (error) {
			} finally {
				this.loading_remove_asset_from_assets_group = false;
			}
		}
	},
	computed: {
		data_table_fields() {
			return [
				{
					key: "name",
					label: this.$t("entities.asset.name")
				},
				{
					key: "total_assets",
					label: this.$t("entities.asset.custom_id")
				},
				{
					key: "options",
					label: this.$t("general.options")
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
.edit-assets-group-form {
	display: grid;
	grid-template-columns: 350px auto;
	column-gap: 15px;
	row-gap: 10px;
}
</style>
