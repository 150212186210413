<template lang="html">
	<div class="relative">
		<input
			type="text"
			class="text-input form-control"
			v-model="input_value"
			:placeholder="placeholder"
			:disabled="disabled"
			:autocomplete="disable_autocomplete ? 'off' : null"
		/>

		<div
			v-if="clean_button"
			class="text-input-remove grey2-color cursor-pointer full-height flex-vertical-center"
			v-on:click="clean_input_value"
		>
			<Icon icon="close" />
		</div>
	</div>
</template>

<script>
export default {
	name: "TextInput",
	props: {
		value: String,
		placeholder: String,
		clean_button: Boolean,
		disabled: Boolean,
		debounce: Boolean,
		disable_autocomplete: Boolean
	},
	data() {
		return {
			input_value: this.value,
			debounce_data: null
		};
	},
	methods: {
		emit_input_value() {
			if (this.debounce) {
				clearTimeout(this.debounce_data);
				this.debounce_data = setTimeout(() => {
					this.$emit("input", this.input_value);
				}, 600);
			} else {
				this.$emit("input", this.input_value);
			}
		},
		clean_input_value() {
			this.input_value = null;
			this.emit_input_value();
			this.$emit("value_cleaned");
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		},
		value(new_value) {
			if (new_value != this.input_value) this.input_value = new_value;
		}
	}
};
</script>

<style>
.form-control {
	margin: 0;
	font-family: inherit;
	box-sizing: border-box;
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}
</style>

<style lang="css" scoped>
.text-input {
	padding-right: 25px;
}
.text-input-remove {
	position: absolute;
	right: 10px;
	top: -2px;
}
</style>
