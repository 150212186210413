<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else class="full-width full-height">
			<AssetsGroupSystemsTable
				:filter_text="filter_text"
				:assets_group_systems_summary="filtered_assets_group_systems_summary"
			/>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import AssetsGroupSystemsTable from "./AssetsGroupSystemsTable.vue";

import AssetsGroupSystemsSummaryFinderClass from "@src/assets-group/application/assets-group-systems-summary-finder.js";
import EnergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder.js";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";

const AssetsGroupSystemsSummaryFinder =
	new AssetsGroupSystemsSummaryFinderClass();
const EnergySystemsFinder = new EnergySystemsFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetsGroupSystems",
	components: {
		Loader,
		Alert,
		AssetsGroupSystemsTable
	},
	props: {
		assets_group_id: String,
		filter_text: String,
		custom_filters: [Object, null],
		force_data_download: Boolean,
		filter_systems_in_asset: String
	},
	data() {
		return {
			loading: true,
			error: null,
			assets_group_systems_summary: null
		};
	},
	async mounted() {
		this.load_data();
	},
	methods: {
		async load_data(force_download = false) {
			try {
				this.loading = true;

				this.assets_group_systems_summary = null;
				var systems;
				var promises = [];

				if (this.assets_group_id == "default")
					promises.push(
						AssetsGroupSystemsSummaryFinder.get_default_assets_group_systems_summary(
							force_download
						).then((data) => {
							this.assets_group_systems_summary = data;
						})
					);
				promises.push(
					EnergySystemsFinder.get_user_energy_systems(force_download).then(
						(data) => {
							systems = data;
						}
					)
				);
				await Promise.all(promises);

				if (this.filter_systems_in_asset) {
					const asset_id = this.filter_systems_in_asset;
					const asset_energy_systems =
						await AssetEnergySystemsFinder.get_asset_energy_systems(asset_id);
					const asset_energy_systems_ids = asset_energy_systems.map(
						(item) => item.id
					);

					this.assets_group_systems_summary =
						this.assets_group_systems_summary.filter(
							(item) => asset_energy_systems_ids.indexOf(item.system_id) != -1
						);
				}

				for (let index in this.assets_group_systems_summary) {
					var item = this.assets_group_systems_summary[index];
					const system = systems.find((system) => system.id == item.system_id);
					item.system_name = system.name;
					item.cups = system.cups;
					item.system_provider_id = system.provider_data
						? system.provider_data.system_id_in_provider
						: null;
					item.system_in_assets_number = system.assets
						? system.assets.length
						: 0;
				}
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		filtered_assets_group_systems_summary() {
			if (!this.custom_filters) {
				this.$emit(
					"assets_group_systems_summary_loaded",
					this.assets_group_systems_summary
				);
				return this.assets_group_systems_summary;
			}

			let result = this.assets_group_systems_summary;
			if (this.custom_filters.status_provided) {
				result = result.filter((item) => {
					return (
						this.custom_filters.status_provided.indexOf(item.status_provided) !=
						-1
					);
				});
			}

			if (this.custom_filters.provider_names) {
				result = result.filter((item) => {
					return (
						this.custom_filters.provider_names.indexOf(item.provider_name) != -1
					);
				});
			}

			if (this.custom_filters.systems_info) {
				result = result.filter((item) => {
					if (!item.system_info) return false;

					for (let index in this.custom_filters.systems_info) {
						if (!item.system_info[this.custom_filters.systems_info[index]])
							return false;
					}

					return true;
				});
			}

			if (this.custom_filters.cups) {
				const filter_cups_lower_case = this.custom_filters.cups.toLowerCase();

				result = result.filter((item) => {
					const system_cups = item.cups;
					if (!system_cups) return false;

					const system_cups_lowercase = system_cups.toLowerCase();
					return system_cups_lowercase.search(filter_cups_lower_case) != -1;
				});
			}

			this.$emit("assets_group_systems_summary_loaded", result);

			return result;
		}
	},
	watch: {
		assets_group_id() {
			this.load_data();
		},
		force_data_download(new_value) {
			if (new_value) this.load_data(true);
		}
	}
};
</script>
