<template>
	<div class="flex-auto-full-height-content white-bg">
		<div class="header p-b">
			<PageHeader :text="$t('alerts.header_label')" close_name="app" />
			<div v-if="!loading">
				<div class="display-flex-space-between border-b p-sm">
					<div class="flex-vertical-center text-uppercase grey2-color font-700">
						<div
							v-on:click="alerts_filter.is_archived = false"
							class="cursor-pointer"
							v-bind:class="{
								'grey1-color': alerts_filter.is_archived == false
							}"
						>
							{{ $t("alerts.active_alerts") }}
						</div>
						<div
							v-on:click="alerts_filter.is_archived = true"
							class="m-l cursor-pointer"
							v-bind:class="{
								'grey1-color': alerts_filter.is_archived == true
							}"
						>
							{{ $t("alerts.archived_alerts") }}
						</div>
					</div>

					<Switcher
						v-model="alerts_from_systems_or_assets"
						:data="[
							{
								multilingual_label: 'alerts.energy_systems',
								value: 'systems'
							},
							{
								multilingual_label: 'alerts.assets',
								value: 'assets'
							}
						]"
					/>
				</div>

				<div class="p-sm border-b display-flex-space-between">
					<AlertsFilter
						:alerts_from_systems_or_assets="alerts_from_systems_or_assets"
						:alerts_filter="alerts_filter"
						:total_alerts="total_alerts"
						v-on:filter="search_alerts"
						v-on:reset_filter="reset_filter"
					/>
					<div
						v-if="$is_number(total_alerts)"
						class="flex-vertical-bottom grey1-color font-bold p-b-xs"
					>
						<span> {{ $t("alerts.total_alerts") }}: {{ total_alerts }} </span>
					</div>
				</div>
			</div>
		</div>

		<div class="content white-bg relative">
			<div
				v-if="show_popup"
				class="absolute full-width full-height full-size-popup-bg"
			>
				<div class="full-width full-height flex-center-content">
					<AlertInfo
						v-if="alert_to_view"
						:alert="alert_to_view"
						v-on:close="close_popup"
					/>
				</div>
			</div>

			<div v-if="loading" class="flex-center-content full-height">
				<Loader />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="alerts_data" class="full-width full-height">
				<div
					v-if="!alerts_data.data || alerts_data.data.length == 0"
					class="p-sm"
				>
					<Alert type="info" message="general.errors.no_data_available" />
				</div>
				<DataTable
					v-else
					class="font-s"
					:items="alerts_data.data"
					:fields="table_fields"
					:default_order_by="alerts_filter.order_by"
					:default_order="alerts_filter.order"
					:async_actions="true"
					:custom_current_page="alerts_data.current_page"
					:custom_total_pages="alerts_data.total_pages"
					v-on:sort_changed="sort_changed"
					v-on:page_changed="page_changed"
				>
					<template #head(archive_alert)="data">
						<div class="display-flex gap-s">
							<Checkbox
								v-model="all_alerts_selected_to_archive"
								:no_value="some_alerts_selected_to_archive"
							/>

							<div v-if="removing_selected_alerts_to_archive">
								<Loader size="18" />
							</div>
							<div
								v-else-if="any_alert_selected_to_archive"
								class="grey1-color cursor-pointer"
								v-on:click="remove_selected_alerts_to_archive"
							>
								<Icon
									:icon="alerts_filter.is_archived ? 'unarchive' : 'archive'"
									size="18"
								/>
							</div>
						</div>
					</template>
					<template #cell(archive_alert)="data">
						<Checkbox v-model="selected_alerts_to_archive[data.item.id]" />
					</template>

					<template #cell(energy_system.provider_data.provider_name)="data">
						<EnergyProviderImg :provider_name="data.value" :height_in_px="20" />
					</template>

					<template #cell(extra_data.severity)="data">
						<span
							:class="{
								'danger-bg': data.value == 1,
								'warning-bg': data.value == 2,
								'blue1-bg': data.value == 3
							}"
							class="p-xxs font-700 font-s white rounded-s text-uppercase"
						>
							{{
								$t("entities.alert.extra_data.severity_options." + data.value)
							}}
						</span>
					</template>

					<template
						v-if="alerts_from_systems_or_assets == 'systems'"
						#cell(description)="data"
					>
						<span v-if="data.item.extra_data.provider_alert_message">
							{{ data.item.extra_data.provider_alert_message }}
						</span>
						<span v-else-if="data.item.extra_data.suspicious_data_type">
							{{
								$t(
									"entities.alert.extra_data.suspicious_data_alert_options." +
										data.item.extra_data.suspicious_data_type
								)
							}}
						</span>
						<span v-else-if="$is_number(data.item.extra_data.pr)">
							PR = {{ data.item.extra_data.pr }}%
						</span>
					</template>

					<template #cell(shortcuts)="data">
						<AssetShortcuts :asset_id="data.item.asset_id" />
					</template>

					<template #cell(alert_actions)="data">
						<div class="flex-horizontal-right gap-xs">
							<Tooltip
								v-if="
									data.item.alert_type == 'systems_alert' ||
									(data.item.alert_type == 'provider_alert' &&
										data.item.energy_system &&
										data.item.energy_system.assets) ||
									data.item.alert_type ==
										'new_asset_imported_with_fields_to_review'
								"
								:tooltip_text="$t('general.view')"
								:single_line_tooltip_text="true"
								position="bottom"
							>
								<template #content>
									<button
										class="btn btn-primary"
										v-on:click="view_alert(data.item)"
									>
										<Icon icon="eye" size="16" />
									</button>
								</template>
							</Tooltip>

							<ArchiveAlertButton
								:alert_id="data.item.id"
								v-on:alert_archived="alert_archived"
								:new_is_archive_value="!alerts_filter.is_archived"
							/>
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import Switcher from "@sub_components/Switcher.vue";
import AlertsFilter from "./AlertsFilter.vue";
import DataTable from "@sub_components/DataTable.vue";
import Checkbox from "@form_components/Checkbox.vue";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg.vue";
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts.vue";
import AlertInfo from "./AlertInfo.vue";
import ArchiveAlertButton from "./alerts-sub-components/ArchiveAlertButton.vue";

import moment from "moment";

import SystemsAlertsFinderClass from "@src/alert/application/systems-alerts-finder.js";
import AssetsAlertsFinderClass from "@src/alert/application/assets-alerts-finder.js";
import EnergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder.js";
import AssetsFinderClass from "@src/asset/application/assets-finder.js";
import AlertUpdaterClass from "@src/alert/application/alert-updater.js";

const SystemsAlertsFinder = new SystemsAlertsFinderClass();
const AssetsAlertsFinder = new AssetsAlertsFinderClass();
const EnergySystemsFinder = new EnergySystemsFinderClass();
const AssetsFinder = new AssetsFinderClass();
const AlertUpdater = new AlertUpdaterClass();

export default {
	name: "Alerts",
	components: {
		Loader,
		Alert,
		PageHeader,
		Switcher,
		AlertsFilter,
		DataTable,
		Checkbox,
		EnergyProviderImg,
		AssetShortcuts,
		AlertInfo,
		ArchiveAlertButton
	},
	data() {
		return {
			loading: false,
			error: null,
			alerts_from_systems_or_assets: "systems", //systems || assets
			alerts_filter: {},
			alerts_data: null,
			table_page: 1,

			removing_selected_alerts_to_archive: false,
			selected_alerts_to_archive: {},
			all_alerts_selected_to_archive: false,

			show_popup: null,
			alert_to_view: null
		};
	},
	mounted() {
		this.reset_filter();
		if (this.$route.query && Object.keys(this.$route.query).length > 0) {
			const query_params = this.$route.query;
			if (query_params.alerts_from_systems_or_assets) {
				this.alerts_from_systems_or_assets =
					query_params.alerts_from_systems_or_assets;
				if (query_params.alerts_from_systems_or_assets == "assets") {
					if (query_params.assets) {
						this.alerts_filter.assets = query_params.assets.split(",");
					}
				} else if (query_params.alerts_from_systems_or_assets == "systems") {
					if (query_params.systems) {
						this.alerts_filter.energy_systems = query_params.systems.split(",");
					}
				}
			}
		}

		this.search_alerts();
	},
	methods: {
		reset_filter() {
			this.alerts_filter = {
				date_from: moment().subtract(1, "days").toDate(),
				date_to: new Date(),
				alert_types: null,
				energy_systems: null,
				severities: null,
				order_by: "date",
				order: "desc",
				is_archived: false
			};
		},
		async search_alerts(filter) {
			this.loading = true;
			this.close_popup();
			this.all_alerts_selected_to_archive = false;
			this.reset_alerts_to_archive();

			try {
				for (let key in filter) {
					this.alerts_filter[key] = filter[key];
				}

				let cloned_filters = Object.assign({}, this.alerts_filter);
				const new_sort_data_convertion = {
					date: "date",
					"extra_data.severity": "severity"
				};
				cloned_filters.order_by =
					new_sort_data_convertion[cloned_filters.order_by];

				const result =
					this.alerts_from_systems_or_assets == "systems"
						? await SystemsAlertsFinder.get_systems_alerts(
								cloned_filters,
								this.table_page
						  )
						: await AssetsAlertsFinder.get_assets_alerts(
								cloned_filters,
								this.table_page
						  );

				this.alerts_data = result;

				if (this.alerts_from_systems_or_assets == "systems")
					await this.add_energy_systems_props_to_alerts_data();
				else if (this.alerts_from_systems_or_assets == "assets")
					await this.add_assets_props_to_alerts_data();
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		async add_energy_systems_props_to_alerts_data() {
			const energy_systems =
				await EnergySystemsFinder.get_user_energy_systems();

			this.alerts_data.data.forEach((alert) => {
				const energy_system_id = alert.system_id;
				const energy_system = energy_systems.find(
					(item) => item.id == energy_system_id
				);

				alert.energy_system = energy_system;
			});
		},
		async add_assets_props_to_alerts_data() {
			for (let index in this.alerts_data.data) {
				let alert = this.alerts_data.data[index];
				const alert_asset = await AssetsFinder.get_asset_by_id(alert.asset_id);

				alert.asset_name = alert_asset.name;
				alert.asset_custom_id = alert_asset.custom_id || null;
			}
		},
		sort_changed(new_sort_data) {
			this.search_alerts(new_sort_data);
		},
		page_changed(data) {
			this.table_page = data.new_page;
			this.search_alerts();
		},

		view_alert(alert) {
			this.show_popup = true;
			this.alert_to_view = alert;
		},
		close_popup() {
			this.show_popup = false;
		},

		alert_archived(alert_id) {
			const alerts_index = this.alerts_data.data.findIndex((alert) => {
				return alert.id == alert_id;
			});

			this.alerts_data.data.splice(alerts_index, 1);
		},
		reset_alerts_to_archive() {
			this.selected_alerts_to_archive = {};
		},
		async remove_selected_alerts_to_archive() {
			this.removing_selected_alerts_to_archive = true;

			try {
				const new_is_archived = this.alerts_filter.is_archived ? false : true;

				const alerts_ids_to_archive = Object.keys(
					this.selected_alerts_to_archive
				);

				for (let index in alerts_ids_to_archive) {
					const alert_id = alerts_ids_to_archive[index];
					await AlertUpdater.update_alert(alert_id, {
						is_archived: new_is_archived
					});
					this.alert_archived(alert_id);
				}

				if (this.all_alerts_selected_to_archive) {
					this.table_page = 1;
					this.search_alerts();
				}

				this.all_alerts_selected_to_archive = false;
				this.reset_alerts_to_archive();
			} catch (error) {
			} finally {
				this.removing_selected_alerts_to_archive = false;
			}
		}
	},
	computed: {
		table_fields() {
			let result = [
				{
					key: "archive_alert"
				},
				{
					key: "date",
					label: this.$t("entities.alert.date"),
					sortable: true,
					class: "no-wrap",
					formatter: (value) => {
						return this.$format_date_to_day_hour_minute(value);
					}
				}
			];

			if (this.alerts_from_systems_or_assets == "systems") {
				result = result.concat([
					{
						key: "energy_system.name",
						label: this.$t("entities.energy_system.name"),
						sortable: false
					},
					{
						key: "energy_system.provider_data.system_id_in_provider",
						label: this.$t(
							"entities.energy_system.provider_data.system_id_in_provider"
						),
						sortable: false
					},
					{
						key: "alert_type",
						label: this.$t("entities.alert.alert_type"),
						sortable: false,
						formatter: (value) => {
							return this.$t("entities.alert.alert_type_options." + value);
						}
					},
					{
						key: "energy_system.provider_data.provider_name",
						label: this.$t(
							"entities.energy_system.provider_data.provider_name"
						),
						sortable: false
					},
					{
						key: "extra_data.severity",
						label: this.$t("entities.alert.extra_data.severity"),
						sortable: true
					},
					{
						key: "description",
						label: this.$t("entities.alert.extra_data.provider_alert_message"),
						sortable: false
					},
					{
						key: "extra_data.provider_alert_code",
						label: this.$t("entities.alert.extra_data.provider_alert_code"),
						sortable: false
					}
				]);
			} else if (this.alerts_from_systems_or_assets == "assets") {
				result = result.concat([
					{
						key: "asset_custom_id",
						label: this.$t("entities.asset.custom_id"),
						sortable: false
					},
					{
						key: "asset_name",
						label: this.$t("entities.asset.name"),
						sortable: false
					},
					{
						key: "alert_type",
						label: this.$t("entities.alert.alert_type"),
						sortable: false,
						formatter: (value) => {
							return this.$t("entities.alert.alert_type_options." + value);
						}
					},
					{
						key: "extra_data.alert_ids",
						label: this.$t("general.quantity"),
						sortable: false,
						formatter: (alert_ids) => {
							return alert_ids ? alert_ids.length : null;
						}
					},
					{
						key: "shortcuts",
						label: this.$t("general.shortcuts"),
						sortable: false
					}
				]);
			}

			result.push({
				key: "alert_actions"
			});

			return result;
		},
		any_alert_selected_to_archive() {
			if (!this.selected_alerts_to_archive) return false;

			for (let alert_id in this.selected_alerts_to_archive) {
				if (this.selected_alerts_to_archive[alert_id]) return true;
			}

			return false;
		},
		some_alerts_selected_to_archive() {
			if (Object.keys(this.selected_alerts_to_archive).length == 0)
				return false;

			let total_selected_alerts_to_archive = 0;
			for (let alert_id in this.selected_alerts_to_archive) {
				if (this.selected_alerts_to_archive[alert_id] == true)
					total_selected_alerts_to_archive++;
			}

			return (
				total_selected_alerts_to_archive > 0 &&
				this.alerts_data.data.length > total_selected_alerts_to_archive
			);
		},
		total_alerts() {
			return this.alerts_data &&
				this.$is_number(this.alerts_data.total_elements)
				? this.alerts_data.total_elements
				: null;
		}
	},
	watch: {
		alerts_from_systems_or_assets(new_value) {
			this.close_popup();

			if (new_value == "assets") {
				this.alerts_filter.energy_systems = null;
				this.alerts_filter.severities = null;
				this.alerts_filter.alert_types = null;
			} else if (new_value == "systems") {
				this.alerts_filter.alert_types = null;
			}

			this.search_alerts();
		},
		"alerts_filter.is_archived"() {
			this.search_alerts();
		},
		all_alerts_selected_to_archive(new_value) {
			if (new_value) {
				this.alerts_data.data.forEach((alert) => {
					this.selected_alerts_to_archive[alert.id] = true;
				});
			} else {
				this.reset_alerts_to_archive();
			}
		}
	}
};
</script>
