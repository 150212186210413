import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_default_assets_group_summary() {
		const url = "/api/assets-group/default/summary";
		const backend_result = await this.get(url);

		return BackendObjectConverter(backend_result, {
			weightedAveragePr: {
				local_name: "pr_average",
				formatter: (value) => {
					return value * 100;
				}
			}
		});
	}

	async get_assets_group_summary(assets_group_id) {
		const url = "/api/assets-group/" + assets_group_id + "/summary";
		const backend_result = await this.get(url);

		return BackendObjectConverter(backend_result, {
			weightedAveragePr: {
				local_name: "pr_average",
				formatter: (value) => {
					return value * 100;
				}
			}
		});
	}
}
